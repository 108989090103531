/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the  appropriate
// layout file, like app/views/layouts/application.html.erb

// this is to remember all listener on a object
import 'arrive/src/arrive';

import '../auth'; // async OK
import '../child_management'; // async OK reste 3 fichier
import '../contract_editor'; // async OK reste timeslot
import '../design_system';
import '../gist';
import '../components';

import '../form'; // async OK

import '../marketplace';

import '../subscriptions';
import '../social';
import '../utm_data';
import {} from 'jquery-ujs';

// admin is loaded here but all is chunked
import '../admin';

import('../controllers');

// We only load push notification for nounou-top
// eslint-disable-next-line no-undef
if (isNounouTop) {
  require('../notification');
} else {
  global.notificationInit = () => import('../notification/components/notification-window').then(module => module.notificationInit());
}

const jQuery = require('jquery');
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

if (!window.__appClientLoaded) {
  window.__appClientLoaded = true;
  require('../components/event_target_logger');
  require('@hotwired/turbo');
}
