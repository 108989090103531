import Offcanvas from 'bootstrap/js/dist/offcanvas';
import { popupLinkConfig } from '../config/popup-link';
import { yieldToMain } from './helpers';

const OffcanvasClass = {
  canvasSelector: '.offcanvas',
  sliderBtnSelector: '.offcanvas__slider',
  canvasBodySelector: '.offcanvas-body',
  canvasElement: null,
  sliderBtn: null,
  canvasBody: null,
  // __Variables
  touchingElement: false,
  startTime: 0,
  startX: 0,
  startY: 0,
  currentX: 0,
  currenty: 0,
  lastX: 0,
  lastY: 0,
  isOpen: false,
  isMoving: false,
  canvasElementHeight: 0,
  moveY: 0,
  dragDirection: 0,
  init: function() {
    this.canvasElement = document.querySelector(this.canvasSelector);
    this.sliderBtn = this.canvasElement.querySelector(this.sliderBtnSelector);
    this.canvasBody = this.canvasElement.querySelector(this.canvasBodySelector);
    this.addEventListeners();
  },
  // __Event listeners
  addEventListeners: function() {
    const that = this;
    this.canvasElement.addEventListener('touchstart', (event) => {
      that.onTouchStart(event);
    }, { passive: false });
    this.canvasElement.addEventListener('touchmove', (event) => {
      that.onTouchMove(event);
    }, { passive: false });
    this.canvasElement.addEventListener('touchend', (event) => {
      that.onTouchEnd(event);
    }, { passive: false });
  },
  onTouchStart: function(event) {
    // START TIME
    this.startTime = new Date().getTime();
    this.startY = event.touches[0].pageY;
    this.startX = event.touches[0].pageX;
    this.currentX = 0;
    this.currentY = 0;
    const focusOnSliderBtn = this.sliderBtn.contains(event.target);

    if (this.canvasBody.scrollTop <= 0 || focusOnSliderBtn) {
      this.touchingElement = true;
      this.touchStart(this.startY, this.startX);
    }
  },
  onTouchMove: function(event) {
    if (!this.touchingElement) { return; }

    this.currentY = event.touches[0].pageY;
    this.currentX = event.touches[0].pageX;

    const translateY = this.currentY - this.startY; // distance moved in the x axis
    const translateX = this.currentX - this.startX; // distance moved in the y axis

    this.touchMove(event, this.currentY, this.currentX, translateY, translateX);
  },
  onTouchEnd: function(event) {
    // Ajout l'event de fermeture si le scroll touche le haut (sans animation par contre)
    // if (canvasBody.scrollTop <= 0) {
    //     console.log("ON TOUCHE LE TOP")
    //     canvasElement.classList.add("offcanvas--no-transition");
    //     touchingElement = true;
    //     isMoving = true;
    //
    // }
    if (!this.touchingElement) { return; }

    this.touchingElement = false;
    const translateY = this.currentY - this.startY; // distance moved in the x axis
    const translateX = this.currentX - this.startX; // distance moved in the y axis
    // start time
    const timeTaken = (new Date().getTime() - this.startTime);

    this.touchEnd(this.currentY, this.currentX, translateY, translateX, timeTaken);
  },
  // Methodes__
  touchStart: function(startY, startX) {
    const canvasOpen = this.canvasElement.classList.contains('show');
    this.isOpen = (canvasOpen !== null);

    this.canvasElement.classList.add('offcanvas--no-transition');
    this.isMoving = true;
    this.canvasElementHeight = this.canvasElement.offsetHeight;
    this.lastY = startY;
    this.lastX = startX;

    if (this.isOpen) {
      this.moveY = 0;
    } else {
      this.moveY = -this.canvasElementHeight;
    }

    this.dragDirection = '';
  },
  touchMove: function(event, currentY, currentX, translateY, translateX) {
    if (!this.dragDirection) {
      if (Math.abs(translateY) >= Math.abs(translateX)) {
        this.dragDirection = 'vertical';
      } else {
        this.dragDirection = 'horizontal';
      }
      const that = this;
      requestAnimationFrame(() => {
        that.updateUi();
      });
    }
    if (this.dragDirection === 'horizontal') {
      this.lastY = currentY;
      this.lastX = currentX;
    } else {
      if (this.moveY + (currentY - this.lastY) > 0 && this.moveY + (currentY - this.lastY) > -this.canvasElementHeight && event.cancelable) {
        this.moveY = this.moveY + (currentY - this.lastY);
        event.preventDefault();
      } else if (this.moveY + (currentY - this.lastY) < 0 && this.moveY + (currentY - this.lastY) > -this.canvasElementHeight) {
        event.stopPropagation();
      }

      this.lastY = currentY;
      this.lastX = currentX;
    }
  },
  touchEnd: function(currentY, currentX, translateY, translateX, timeTaken) {
    this.isMoving = false;
    const velocity = 0.3;

    if (currentY === 0 && currentX === 0) {
      this.canvasElement.classList.remove('offcanvas--no-transition');
    } else {
      if (this.isOpen) {
        if ((translateY > (this.canvasElementHeight) / 2) || (Math.abs(translateY) / timeTaken > velocity)) {
          this.closeCanvas(translateY);
          this.isOpen = false;
        } else {
          this.openCanvas();
          this.isOpen = true;
        }
      } else {
        if (translateY > this.canvasElementHeight / 2) {
          this.openCanvas();
          this.isOpen = true;
        } else {
          this.closeCanvas(translateY);
          this.isOpen = false;
        }
      }
    }
    this.canvasElement.classList.remove('offcanvas--no-transition');
  },
  updateUi: function() {
    if (this.isMoving) {
      this.canvasElement.style.transform = 'translateY(' + this.moveY + 'px)';
      this.canvasElement.style.webkitTransform = 'translateY(' + this.moveY + 'px)';
      const that = this;
      requestAnimationFrame(() => {
        that.updateUi();
      });
    }
  },
  closeCanvas: function(translateY) {
    const that = this;

    function OnTransitionEnd() {
      that.canvasBody.scrollTop = 0;
      that.canvasElement.removeEventListener('transitionend', OnTransitionEnd, false);
    }

    if (translateY > 0 || !this.isOpen) {
      this.canvasElement.style.transform = '';
      this.canvasElement.style.webkitTransform = '';
      this.canvasElement.classList.remove('offcanvas--no-transition');
      this.canvasInstance().hide();
      this.canvasElement.addEventListener('transitionend', OnTransitionEnd, false);
    }
  },
  canvasInstance: function() {
    return Offcanvas.getOrCreateInstance(this.canvasElement);
  },
  openCanvas: function() {
    this.canvasElement.style.transform = '';
    this.canvasElement.style.webkitTransform = '';
  },
};

document.addEventListener('turbo:load', async() => {
  await yieldToMain();
  OffcanvasClass.init();
});

document.addEventListener('turbo:before-cache', function() {
  OffcanvasClass.closeCanvas();
  $('.offcanvas-backdrop').remove();
});

class OffCanvasComponent {
  constructor(title, body, position = 'auto', customClass = '') {
    this.title = title;
    this.body = body;
    this.customClass = customClass;
    this.position = position === 'auto' ? 'bottom' : position;
    this.element = 'display-content-offcanvas-' + this.position;
    this.closeButtonClass = 'offcanvas-close';
    this.injectContent();
    this.offCanvasElement = document.getElementById(this.element);
    this.offcanvas = new Offcanvas(this.offCanvasElement);
    this.bindCloseElement();
  }

  injectContent() {
    $(`#${this.element} .offcanvas-title`).html(this.title);
    $(`#${this.element} .offcanvas-body`).html(this.body);
    $(`#${this.element}`).addClass(`offcanvas-${this.position} ${this.customClass}`);
  }

  bindCloseElement() {
    const offCanvas = this.offcanvas;
    $(document).on('click', `#${this.element} .${popupLinkConfig.closeBtnClass}`, function() {
      offCanvas.hide();
    });

    // Close button added on desktop version
    $('body').on('click', `.${this.closeButtonClass}`, function() {
      offCanvas.hide();
    });
  }

  show() {
    this.offcanvas.show();
  }
}

export { OffCanvasComponent };
