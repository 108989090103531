import { yieldToMain } from '../../components/helpers';

const onClick = async (event) => {
  const target = event.target.closest('.toggle-parameters');
  if (!target) {
    return;
  }
  const title = document.getElementById('payslip__parameters-title');
  if (!title) return;
  // Ouvrir l'accordéon si fermé
  if (!title.classList.contains('accordion-item--active')) {
    const button = title.querySelector('button');
    if (button) {
      button.click();
    }
  }
  // Faire défiler jusqu'à l'élément
  const {default: scrollToItem} = await import('../../design_system/inputs/scroll_on_focus')
  scrollToItem($(title));
}
document.addEventListener('turbo:load', async function() {
  const body = document.body;

  // Vérifiez la classe spécifique une seule fois au chargement
  if (!body.classList.contains('child-payslip-show')) {
    return;
  }

  await yieldToMain();

  body.addEventListener('click',onClick , { passive: true });
});
