
import './turbo';
import './script_loader';
import './scroll_detection';
import './cookie-consent';
import './lateral_menu';
import './loaders';
import './review';
import './remove_capitalkoala';
import './share';
import './autoreplace';

global.selectBottomMenu = (view) => import('./bottom_menu').then(module => module.selectBottomMenu(view));
global.menuLoader = (loaderHtml) => import('./bottom_menu').then(module => module.menuLoader(loaderHtml));
