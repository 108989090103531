import { yieldToMain } from '../../components/helpers';

const initializeSidebar = async function() {
  await yieldToMain();
  // console.log('initializeSidebar');
  configureSidebars();
  configureMiniSidebars();
};

function configureSidebars() {
  const $lateralMenu = $('#lateral-menu');
  const $adminLateralMenu = $('#admin-lateral-menu');
  if ($lateralMenu.length === 0 && $adminLateralMenu.length === 0) {
    return;
  }
  $lateralMenu.removeClass('fxp-sidebar-mini fxp-sidebar-open fxp-sidebar-locked fxp-sidebar-force-open');
  $adminLateralMenu.removeClass('fxp-sidebar-mini fxp-sidebar-open fxp-sidebar-locked fxp-sidebar-force-open');

  if ($lateralMenu.hasClass('fxp-sidebar-ready')) {
    $lateralMenu.sidebar('destroy');
  }

  if ($adminLateralMenu.hasClass('fxp-sidebar-ready')) {
    $adminLateralMenu.sidebar('destroy');
  }

  const smallScreen = $(window).width() < 1200;
  // attention faire en sorte que bigScreen soit en cohérence avec la variable $forced-open-breakpoint dans le sass
  const bigScreen = $(window).width() >= 2000;
  const targetMode = $('#page').hasClass('page--lateral-menu-open') ? 'open' : 'closed';
  const displayedRetracted = smallScreen ? true : (bigScreen ? false : targetMode === 'closed');

  $lateralMenu.addClass(displayedRetracted ? 'fxp-sidebar-mini' : 'fxp-sidebar-open');
  $lateralMenu.sidebar({
    showObfuscator: (displayedRetracted && !bigScreen),
    classWrapper: 'fxp-sidebar-wrapper lateral-menu-wrapper',
    locked: !displayedRetracted,
  });
  if ($('#toggle-sidebar').length > 0) {
    $('#lateral-menu').sidebar('attachToggle', $('#toggle-sidebar'));
  }
  // on considère qu'on peut afficher le panneau admin déplié sans impact sur le design
  // dès lors que la taille $large-monitor-breakpoint est dépassée de 300 px (la largeur du panneau admin)
  const enoughSpaceForAdmin = $(window).width() > 1500;

  $adminLateralMenu.addClass(!enoughSpaceForAdmin ? 'fxp-sidebar-mini' : 'fxp-sidebar-open');
  $adminLateralMenu.sidebar({
    showObfuscator: !enoughSpaceForAdmin,
    classWrapper: 'fxp-sidebar-wrapper admin-lateral-menu-wrapper',
    locked: enoughSpaceForAdmin,
  });
}

function configureMiniSidebars() {
  // configure mini sidebar
  const $miniSidebars = $('.fxp-sidebar-mini');

  if ($miniSidebars.length > 0) {
    $miniSidebars.each(function(index) {
      const $miniSidebar = $miniSidebars.eq(index);

      $miniSidebar.on('mouseenter', null, null, function() {
        $miniSidebar.sidebar('open');
      });

      $miniSidebar.on('mouseleave', null, null, function() {
        $miniSidebar.sidebar('close');
      });
    });
  }
}

document.addEventListener('turbo:load', initializeSidebar);

const onResize = function() {
  clearTimeout(window.resizeEvt);
  window.resizeEvt = setTimeout(function() {
    // code to do after window is resized
    initializeSidebar();
  }, 250);
};

$(window).off('resize', onResize);
$(window).on('resize', onResize);
