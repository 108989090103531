/**
 * For this script to work you need to load the facebook sdk first
 * It is loaded with content_for(:facebook_load, true) in your template where you want it to work
 * The facebook sdk is not always loaded as the page load time will be affected by it
 */
// import Popover from 'bootstrap/js/dist/popover';

document.addEventListener('turbo:load', function() {
  $('.facebook-share-button').click(async function() {
    const { default: axios } = await import('axios');
    const $button = $(this);
    const url = $button.data('url');
    const callbackUrl = $button.data('callback-url');
    const origin = $button.data('origin');

    // eslint-disable-next-line no-undef
    FB.ui({
      display: 'popup',
      method: 'share',
      href: url,
    }, function(response) {
      /**
       * A response only occurs if the user is logged into your app using Facebook Login.
       * As we are not sure that there will be a response we remove the code associated to it
       */
      // if (response && !response.error_message) {
      //   axios.post(callbackUrl, {}, {
      //     headers: {
      //       'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content,
      //     },
      //   })
      //     .then(function(response) {
      //       if (origin === 'share_tools') {
      //         $button.hide();
      //       } else if (origin === 'achievements') {
      //         location.reload();
      //       }
      //     });
      // } else {
      //   const popover = Popover.getOrCreateInstance(
      //     $button,
      //     {
      //       placement: 'top',
      //       trigger: 'manual',
      //       content: $button.data('popover-content'),
      //       customClass: 'popover--error',
      //     }
      //   );
      //
      //   popover.show();
      //   setTimeout(function() { popover.hide(); }, 8000);
      // }
    });
  });
});
