import { yieldToMain } from '../../components/helpers';

function setupSelect(item) {
  const itemSelect = item;
  const $item = $(item);

  function initSelect(element) {
    const value = element.value;
    const anychecked = value !== '' && value !== undefined;
    const items = $(element).find('option');
    // on passe en deux fois sur la collection afin d'éviter des conflits en cas de selecteur visant le même élément
    items.each(function(k, item) {
      const metaDatas = $(item).data();
      const uncheckedSelector = metaDatas.uncheckedSelectorDisplay;
      const checkedSelector = metaDatas.checkedSelectorDisplay;
      const undefinedSelector = metaDatas.undefinedSelectorDisplay;
      const definedSelector = metaDatas.definedSelectorDisplay;
      if (undefinedSelector && anychecked) $(undefinedSelector).hide();
      if (definedSelector && anychecked) $(definedSelector).show();
      if (uncheckedSelector) $(uncheckedSelector).hide();
      if (checkedSelector) $(checkedSelector).hide();
      if (undefinedSelector && !anychecked) $(undefinedSelector).show();
      if (definedSelector && !anychecked) $(definedSelector).hide();
    });

    items.each(function(k, item) {
      const metaDatas = $(item).data();
      const checkedSelector = metaDatas.checkedSelectorDisplay;
      const uncheckedSelector = metaDatas.uncheckedSelectorDisplay;

      if (anychecked) {
        if (item.value === value) {
          if (checkedSelector && anychecked) $(checkedSelector).show();
        } else {
          if (uncheckedSelector && anychecked) $(uncheckedSelector).show();
        }
      }
    });
  }

  initSelect(itemSelect);
  const updateOnChange = async function(event) {
    await yieldToMain();
    initSelect(event.target);
    if (event.target.form.dataset.autosubmit) event.target.form.requestSubmit();
  };

  $item.off('change', updateOnChange);
  $item.on('change', updateOnChange);
}

document.arrive('form select[id]', async function(item) {
  await yieldToMain();
  setupSelect(item);
});
