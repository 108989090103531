import 'arrive/src/arrive';

window.before_fetch_url = [];
document.addEventListener('turbo:before-fetch-request', allDisable);
document.addEventListener('ajax:send', allDisable);

document.addEventListener('turbo:render', restaureEnable);
// document.addEventListener('turbo:visit', restaureEnable);
document.addEventListener('turbo:before-cache', restaureEnable);
document.addEventListener('turbo:before-stream-render', restaureEnable);
document.addEventListener('turbo:frame-render', restaureEnable);
document.addEventListener('ajax:complete', restaureEnable);

function allDisable(event) {
  if (event.detail?.fetchOptions?.headers['X-Sec-Purpose'] === 'prefetch') {
    return;
  }
  $('body:not(.notebook)').find('a:not(.always-enabled), button:not(.always-enabled), input[type="submit"]:not(.always-enabled)').each(function() {
    setDisabled($(this));
  });
  clearTimeout(window.restaureEnableTimeout);
  window.restaureEnableTimeout = setTimeout(restaureEnable, 10000);
}

function restaureEnable() {
  $('body:not(.notebook)').find('a, button, input[type="submit"]').filter('[data-tmp-disabled]').each(function() {
    setEnable($(this));
  });
  clearTimeout(window.restaureEnableTimeout);
}

function setDisabled($item) {
  $item.attr('disabled', 'disabled').attr('data-tmp-disabled', 'disabled');
}

function setEnable($item) {
  if ($item.attr('data-tmp-disabled')) {
    $item.attr('disabled', null).attr('data-tmp-disabled', null);
  }
}

document.arrive('[data-turbo-disable-with]', function(element) {
  const $item = $(element);

  function callSetDisabledLabel() {
    setDisabledLabel($item);
  }

  if ($item.attr('type') === 'submit') {
    const form = $item.parents('form').first();
    if (form[0]) {
      form[0].addEventListener('submit', function() {
        setDisabledLabel($item);
      });
    }
  } else {
    $item.off('click', callSetDisabledLabel);
    $item.on('click', callSetDisabledLabel);
  }
});

function setDisabledLabel($item) {
  if (!$item.attr('data-turbo-disable-with') || $item.attr('data-turbo-enable-with')) {
    return true;
  }
  const labelDisabled = $item.attr('data-turbo-disable-with');
  $item.attr('data-turbo-enable-with', $item.html());
  if ($item.hasClass('btn')) {
    $item.addClass('btn--tmp-disabled');
  }
  // finish the flow before setting the label
  setTimeout(function() {
    // console.log("set labelDisabled");
    $item.html(labelDisabled);
  });
  let $target = $item;
  // if it's a link to a turbo frame then need to listen the frame instead of the link
  if ($item.data('turboFrame') && $item.data('turboFrame') !== '_top') {
    $target = $('turbo-frame#' + $item.data('turboFrame'));
    $target.one('turbo:before-fetch-response ajax:complete turbo:frame-render', reEnable);
  }

  function reEnable(event) {
    if (event.detail?.fetchResponse?.header?.('X-Sec-Purpose') === 'prefetch') {
      setTimeout(() => { setEnableLabel($item); }, 100);
    } else {
      setEnableLabel($item);
    }
  }

  let timeout = $item.attr('data-turbo-disable-timeout');
  if (timeout === undefined) {
    timeout = 10000;
  }
  if (timeout > 0) {
    setTimeout(function() {
      $target.off('turbo:before-fetch-response ajax:complete turbo:frame-render', reEnable);
      setEnableLabel($item);
    }, timeout);
  }
}

function setEnableLabel($item) {
  if ($item.currentTarget) {
    $item = $(this);
  }
  const labelEnable = $item.attr('data-turbo-enable-with');
  if (!labelEnable) {
    return true;
  }
  // console.log("setEnableLabel");
  $item.removeClass('btn--tmp-disabled');

  $item.attr('data-turbo-enable-with', null).attr('disabled', null);

  // console.log("set labelDisabled");
  $item.html(labelEnable);
}

document.addEventListener('turbo:render', function() {
  // reset auto disable
  restaureEnable();
  $('[data-turbo-enable-with]').each(() => {
    setEnableLabel($(this));
  });
});
export { setEnableLabel };
