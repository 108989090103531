import './flyer';
import './pages/home';
import './identity_document';
import './service/email_alerts';
import './guide';
import './pictures';
import './minimal_length';
import './listing_tab';

const loadSearch = (autoSelect = true) => {
  if (document.getElementsByClassName('search-band__subcategory').length === 0) {
    return;
  }
  import('./pages/search').then(module =>
    module.hideSubcategories(autoSelect)
  );
};
document.addEventListener('turbo:load', function() {
  // Execute the first time the page load
  loadSearch(false);

  $(document).on('change', '.search-band__category', loadSearch);
});
document.addEventListener('turbo:frame-render', () => loadSearch(false));
global.initMarketplaceSearch = (selector, locationiqPublicMapApiKey, searchableCountryCodes) => import('./pages/search').then(module => module.initMarketplaceSearch(selector, locationiqPublicMapApiKey, searchableCountryCodes));
global.initMapWithOneMarker = (options) => import('./map/map_with_one_marker').then(module => module.initMapWithOneMarker(options));
global.initMapWithTwoMarkers = (options) => import('./map/map_with_two_markers').then(module => module.initMapWithTwoMarkers(options));
