import { yieldToMain } from '../../components/helpers';

document.arrive('a[data-remote=true], a[data-turbo-method], a[data-turbo=true], .remote_modal_link', async function(element) {
  // Yield to the main thread:
  await yieldToMain();
  element.onclick = function(e) {
    if (e && (e.ctrlKey || e.which === 2 || e.button === 4)) {
      e.preventDefault();
      e.currentTarget.click();
      return false;
    }
  };
  element.onauxclick = function(e) {
    if (e && (e.ctrlKey || e.which === 2 || e.button === 4)) {
      e.preventDefault();
      e.currentTarget.click();
      return false;
    }
  };
  // if we want to disable right click menu
  element.oncontextmenu = function(e) {
    if (e) {
      e.preventDefault();
      return false;
    }
  };
});
