
import { yieldToMain } from './helpers';
document.addEventListener('DOMContentLoaded', () => {
  document.arrive('.autoreplace', { existing: true }, async function(item) {
    // Yield to the main thread:
    await yieldToMain();
    const selectorId = item.dataset.selectorId;
    const filler = document.getElementById(selectorId);
    if (!filler) {
      return;
    }
    item.outerHTML = filler.innerHTML;
    filler.remove();
  });
  document.arrive('.autoupdate', { existing: true }, async function(item) {
    // Yield to the main thread:
    await yieldToMain();
    const selectorId = item.dataset.selectorId;
    const filler = document.getElementById(selectorId);
    if (!filler) {
      return;
    }
    item.innerHTML = filler.innerHTML;
    filler.remove();
  });
});
