import 'arrive/src/arrive';

/**
 * L'affichage d'une modal cache les popover existants, sinon ils restent au dessus
 * Ne pas essayer de les mettre en dessous => crée trop de complexité dans le cas ensuite de popover devant être à l'intérieur d'une modale
 *
 */
document.addEventListener('show.bs.modal', (event) => {
  $('.popover').hide();
  // autre souci : l'ouvertue d'une modal sur mobile oblige à rescroller vers le haut, qd elle est appelée d'un contenu placé en bas
});

// tooltips inside of modal
document.addEventListener('turbo:load', function() {
  document.addEventListener('click', function(event) {
    // Vérifie si l'élément cliqué a la classe 'modal-tooltip-icon'
    const element = event.target.closest('.modal-tooltip-icon');
    if (element) {
      const contentId = element.getAttribute('data-hint-content-id');
      $(`#${contentId}`).slideToggle();
    }
  });
});
// Fonction pour trouver le premier élément parent correspondant à un sélecteur donné
const closestParent = function(element, selector) {
  while (element && element !== document) {
    if (element.matches(selector)) return element;
    element = element.parentNode;
  }
  return null;
};
// Fonction appelée lors du clic pour fermer le modal
const closeModal = function(event) {
  const modalElement = closestParent(event.target, '.modal');
  if (modalElement) {
    hideModal(modalElement.id);
  }
};
document.arrive('.modal .popup-link-close', function(element) {
// Suppression de tout ancien gestionnaire d'événement
  element.removeEventListener('click', closeModal);
  // Ajout du nouveau gestionnaire d'événement
  element.addEventListener('click', closeModal);
});

/**
 * @param modalTemplate {string} Escaped javascript html or text, e.g. j render 'template'
 * @param id {string}
 */
const showModalAjax = function(modalTemplate, id, refreshOnShow = true) {
  const modalObject = document.getElementById(id);
  if (modalObject) {
    if (refreshOnShow) {
      const modalWrap = new DOMParser().parseFromString(modalTemplate, 'text/html');
      const body = modalWrap.querySelector('.modal-body').innerHTML;
      modalObject.querySelector('.modal-body').innerHTML = body;
    }
    try {
      const modalController = Stimulus.getControllerForElementAndIdentifier(modalObject, 'modal');
      modalController.show();
    } catch (e) {
      console.error(e);
    }
  } else {
    const modalList = document.getElementById('modal-list');
    if (modalList) {
      modalList.insertAdjacentHTML('beforeend', modalTemplate);
    } else {
      console.error("L'élément avec l'ID 'modal-list' est introuvable.");
    }
  }
};

/**
 * Remove modal the bad way
 * DO NOT USE ELSE ABSOLUTELY NECESSARY
 */
const hideModalManually = function(id) {
  const modal = document.getElementById(id);
  if (modal) {
    const body = document.body;
    modal.remove();
    body.style.removeProperty('overflow');
    body.style.removeProperty('padding-right');
    document.getElementsByClassName('modal-backdrop')[0].remove();
  }
};

const hideModal = function(id) {
  const element = document.getElementById(id);
  if (!element) {
    return;
  }
  try {
    const modalController = Stimulus.getControllerForElementAndIdentifier(element, 'modal');
    if (modalController) {
      modalController.hide();
    }
  } catch (e) {
    console.error(e);
  }
};

const closeAllModal = function() {
  Stimulus.getControllersForIdentifier('modal').forEach((controller) => {
    controller.hide();
  });
};
const openRemoteModal = function(url, level = '') {
  const modalFrame = document.querySelector('turbo-frame#remote_modal' + level);
  if (modalFrame) {
    modalFrame.src = url;
    modalFrame.reload();
  }
};
function isMobileOrTablet() {
  return /Mobi|Tablet|iPad|iPhone|Android/i.test(navigator.userAgent);
}
function isSmallScreen() {
  return window.innerWidth <= 768;
}
document.arrive('a[data-turbo-frame="remote_modal_mobile"]', function() {
  const element = this;
  if (isMobileOrTablet() || isSmallScreen()) {
    element.setAttribute('data-turbo-frame', 'remote_modal');
  } else {
    element.setAttribute('data-turbo-frame', '_top');
  }
});

export { showModalAjax, hideModalManually, closeAllModal, openRemoteModal, hideModal };
