import { yieldToMain } from '../../components/helpers';

document.addEventListener('turbo:load', async function() {
  // Yield to the main thread:
  await yieldToMain();
  const $subscriptionsCancellationBlock = $(
    '.subscription-cancellation-form__checkbox-block'
  );

  $subscriptionsCancellationBlock.each(function() {
    if ($(this).find('input').attr('checked') === 'checked') {
      checkCancellationInput($(this), true);
      activateCancellationButton();
    }
  });

  $subscriptionsCancellationBlock.click(function() {
    checkCancellationInput($(this), false);
    activateCancellationButton();
  });

  activateCancellationButton();
});

function checkCancellationInput($cancellationBlock, init) {
  const $input = $cancellationBlock.find('input');
  const $inputValue = $input.attr('checked') === 'checked';
  const check = init ? $inputValue : !$inputValue;
  $input.attr('checked', check);
  $cancellationBlock.find('.subscription-cancellation-form__checkbox').toggleClass('subscription-cancellation-form__checkbox--checked', check);
  $cancellationBlock.closest('.subscription-cancellation-form__element').toggleClass('subscription-cancellation-form__element--checked', check);
}

function activateCancellationButton() {
  const isAllChecked = $('.subscription-cancellation-form input:checkbox:not([checked])').length === 0;
  $('#submit_cancellation').toggle(isAllChecked);
  $('#missing_validation_cancelation').toggle(!isAllChecked);
}
