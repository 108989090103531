import { yieldToMain } from '../../components/helpers';

document.addEventListener('turbo:load', async function() {
  // Yield to the main thread:
  await yieldToMain();
  const $slider = $('.rating-slider');
  if ($slider.length === 0) {
    return;
  }
  $slider.owlCarousel({
    center: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 3,
      },
    },
    loop: true,
    margin: 30,
    dots: false,
    nav: true,
    autoHeight: false,
    slideTransition: '',
    navSpeed: 1000,
    navText: [$('.rating-slider-left'), $('.rating-slider-right')],
  });
});
