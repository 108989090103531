import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';

/**
 * @param element {string} the element in the form of a query selector, e.g. "#id"
 * @return {boolean}
 */
const isInViewport = (element) => {
  const elementObj = document.querySelector(element);
  if (typeof elementObj === 'undefined') {
    return false;
  }
  const rect = elementObj.getBoundingClientRect();
  return rect.top >= 0 &&
      rect.left >= 0 &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
};

const variableEmpty = async(variable, considerZeroEmpty = false) => {
  if (typeof variable === 'undefined') {
    return true;
  } else if (variable === null) {
    return true;
  } else if (variable === false) {
    return true;
  } else if (Array.isArray(variable) && !flatten(variable).length) {
    return true;
  } else if (typeof variable === 'object' && isEmpty(variable)) {
    return true;
  } else if (Number.isInteger(variable)) {
    return considerZeroEmpty && variable === 0;
  } else if (trim(variable) === '') {
    return true;
  }
  return false;
};

const yieldToMain = function() {
  if (globalThis.scheduler?.yield) {
    return globalThis.scheduler.yield();
  }

  // Fall back to yielding with setTimeout.
  return new Promise(resolve => {
    setTimeout(resolve, 0);
  });
};
export { isInViewport, variableEmpty, yieldToMain };
