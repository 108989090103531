
import './contract_time_slot';
// use require and not import !
const ContractEditor = {};
global.ContractEditor = ContractEditor;

// replace already existing factory by real method
ContractEditor.refreshBreadcrumb = (step, substep) => import('./contract_menu').then(module => module.refreshBreadcrumb(step, substep));
ContractEditor.initContractChildrenForm = () => import('./contract_ged_child').then(module => module.initContractChildrenForm());
ContractEditor.initContractCompensationForm = () => import('./contract_compensation').then(module => module.initContractCompensationForm());
ContractEditor.initContractProfileForm = () => import('./contract_profile').then(module => module.initContractProfileForm());
ContractEditor.initContractScheduleForm = () => import('./contract_schedules').then(module => module.initContractScheduleForm());
ContractEditor.initContractMissionsForm = () => import('./contract_missions').then(module => module.initContractMissionsForm());
ContractEditor.initContractSimulationForm = () => import('./contract_simulation').then(module => module.initContractSimulationForm());
ContractEditor.initContractOtherForm = () => import('./contract_other').then(module => module.initContractOtherForm());
ContractEditor.initSalariesHistory = () => import('./contract_history').then(module => module.initSalariesHistory());
ContractEditor.loadVacationHistoryMissingDataWarning = () => import('./contract_history').then(module => module.loadVacationHistoryMissingDataWarning());
ContractEditor.initContractNotebook = () => import('./notebook').then(module => module.initContractNotebook());
