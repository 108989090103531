document.addEventListener('turbo:load', async function() {
  if (!$('body').hasClass('subscription-index')) {
    return;
  }
  // Yield to the main thread:
  const { default: ScrollSpy } = await import('bootstrap/js/dist/scrollspy');
  new ScrollSpy($('#subscription-main-column'), {
    target: '#subscription-lateral-column',
    smoothScroll: true,
  });
});
